import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";
import axios from "axios";
import { env } from "configs/EnvironmentConfig";
import navigationCafeConfig from "configs/NavigationAdminCafeConfig";
export const AdminViews = () => {
  const [stnk, setStnk] = useState(localStorage.getItem('stnk') === 'true');
  const [quotation, setQuotation] = useState(localStorage.getItem('quotation') === 'true');
  const getData = () => {
    axios
      .get(env.API + "/admin/auth/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const data = res.data.data.role.permissions;
        data.forEach((d) => {
          if (d === "MANAGE_STNK") {
            setStnk(true);
            localStorage.setItem('stnk', 'true');
          }else{
            if(d === "MANAGE_QUOTATION"){
              setQuotation(true)
              localStorage.setItem('quotation', 'true');
            }
          }
        });
      })
      .catch((e) => {
        // localStorage.removeItem("token");
        // window.location.href = "/auth/login";
      });
  };
  useEffect(() => {
    // setError(true);

    // if (!data.length) {
    //  showToken(setTokenFound);
    getData();
    // let newArr = [
    //   {
    //     key: "data-vendor/detail",
    //     path: `${ADMIN_PREFIX_PATH}/data_vendor/:id`,
    //   },
    // ];

    // newArr.map((res) => {
    //   navigationCafeConfig.push(res);
    // });
    // }
  }, []);

  

  return (
    <Suspense fallback={<Loading cover="content" />}>
      {stnk == true && (
        <Switch>
          <Route
            path={`${ADMIN_PREFIX_PATH}/pengajuan-stnk`}
            component={lazy(() => import(`./pengajuan-stnk`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-vendor`}
            component={lazy(() => import(`./master-vendor`))}
          />
          <Redirect
            from={`${ADMIN_PREFIX_PATH}`}
            to={`${ADMIN_PREFIX_PATH}/pengajuan-stnk`}
          />
        </Switch>
      )}
       {quotation == true && (
        <Switch>
           <Route
            path={`${ADMIN_PREFIX_PATH}/dashboard-non-anggota`}
            component={lazy(() => import(`./dashboard-non-anggota`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/quotation`}
            component={lazy(() => import(`./quotation`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/quotation-material`}
            component={lazy(() => import(`./quotation-material`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/purchase-request`}
            component={lazy(() => import(`./purchase-request`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/purchase-request-material`}
            component={lazy(() => import(`./purchase-request-material`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/monitoring-po`}
            component={lazy(() => import(`./monitoring-po`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/vendor-non-anggota`}
            component={lazy(() => import(`./data-vendor-non-anggota`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/penerimaan-barang`}
            component={lazy(() => import(`./penerimaan-barang`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/penagihan-penagihan-berjalan`}
            component={lazy(() => import(`./penagihan/penagihan-berjalan`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/penagihan-rekap-pembayaran`}
            component={lazy(() => import(`./penagihan/rekap-pembayaran`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/penagihan-upload-bukti-pembayaran`}
            component={lazy(() => import(`./penagihan/upload-bukti-pembayaran`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/surat-penjualan`}
            component={lazy(() => import(`./surat-penjualan`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/pengaturan-anggota`}
            component={lazy(() => import(`./pengaturan-anggota`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/pengaturan-divisi-kebutuhan-posisi`}
            component={lazy(() => import(`./pengaturan-divisi-kebutuhan-posisi`))}
          />
           <Route
            path={`${ADMIN_PREFIX_PATH}/edit-profile`}
            component={lazy(() => import(`./edit-profile`))}
          />
           <Redirect
            from={`${ADMIN_PREFIX_PATH}`}
            to={`${ADMIN_PREFIX_PATH}/dashboard-non-anggota`}
          />
        </Switch>
      )}
      {stnk == false && quotation == false && (
        <Switch>
          <Route
            path={`${ADMIN_PREFIX_PATH}/home`}
            component={lazy(() => import(`./home`))}
          />

          <Route
            path={`${ADMIN_PREFIX_PATH}/bukti-transfer`}
            component={lazy(() => import(`./bukti-transfer`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/rekap-transaksi`}
            component={lazy(() => import(`./rekap-transaksi`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/download-csv`}
            component={lazy(() => import(`./download-csv`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/riwayat-pembayaran`}
            component={lazy(() => import(`./riwayat-pembayaran`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/add-kendaraan`}
            component={lazy(() => import(`./add-kendaraan`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/list-dealer`}
            component={lazy(() => import(`./list-dealer`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/verifikasi-user`}
            component={lazy(() => import(`./verif-user`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-karyawan`}
            component={lazy(() => import(`./master-karyawan`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-bank`}
            component={lazy(() => import(`./master-bank`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-barang`}
            component={lazy(() => import(`./master-barang`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-kasir`}
            component={lazy(() => import(`./master-kasir`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/daftar-artikel`}
            component={lazy(() => import(`./daftar-artikel`))}
          />
           <Route
              path={`${ADMIN_PREFIX_PATH}/daftar_artikel/:id`}
              component={lazy(() => import(`./daftar-artikel/detail`))}
          />
            <Route
            path={`${ADMIN_PREFIX_PATH}/tambah-artikel`}
            component={lazy(() => import(`./tambah-artikel`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-voucher`}
            component={lazy(() => import(`./master-voucher`))}
          />
          <Route
              path={`${ADMIN_PREFIX_PATH}/master_voucher/:id`}
              component={lazy(() => import(`./master-voucher/detail`))}
          />

           <Route
              path={`${ADMIN_PREFIX_PATH}/master-lokasi`}
              component={lazy(() => import(`./master-lokasi`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/lokasi/:id`}
            component={lazy(() => import(`./titik-pengantaran`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-delivery`}
            component={lazy(() => import(`./master-delivery`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/master-delivery-fee`}
            component={lazy(() => import(`./master-delivery-fee`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/point/:location`}
            component={lazy(() => import(`./delivery-fee`))}
          />

          <Route
            path={`${ADMIN_PREFIX_PATH}/pengajuan-pinjaman`}
            component={lazy(() => import(`./pengajuan-pinjaman`))}
          />

          <Route
            path={`${ADMIN_PREFIX_PATH}/settlement`}
            component={lazy(() => import(`./settlement`))}
          />
          {navigationCafeConfig.map((res, i) => {
            return (
              <Route
                key={i}
                path={res.path}
                component={lazy(() => import(`./cafe/${res.key}`))}
              />
            );
          })}
          <Route
            path={`${ADMIN_PREFIX_PATH}/cafe/data_vendor/:id`}
            component={lazy(() => import(`./cafe/data-vendor/detail`))}
          />

          <Route
            path={`${ADMIN_PREFIX_PATH}/cafe/rekap_transaksi/:id`}
            component={lazy(() => import(`./cafe/rekap-transaksi/detail`))}
          />
          <Route
            path={`${ADMIN_PREFIX_PATH}/edit-profile`}
            component={lazy(() => import(`./edit-profile`))}
          />
          <Redirect
            from={`${ADMIN_PREFIX_PATH}`}
            to={`${ADMIN_PREFIX_PATH}/home`}
          />
        </Switch>
      )}
    </Suspense>
  );
};

export default React.memo(AdminViews);
